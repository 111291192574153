import { useRef } from "react";
import { useDBStore } from "../../stores/dbStore";
import { findNodeById } from "../../helpers";
import { Section, Bar } from '@column-resizer/react';
const VerticalBlock = ({ id, renderer }) => {
  const {
    setFocus,
    setActiveNode,
    createNode,
    setDestination,
    createNewBlock,
    currentPage,
    pages,
  } = useDBStore((state) => state);
  const $this = useRef(null);
  const { nodes, blocks } = pages[currentPage];
  const node = nodes[id];
  const parent = nodes[node.parent];
  const isNotBlock = parent.children?.length > 1

  const handleClick = (e) => {

    const lastChildId = node.children.slice(-1)[0];
    const lastChild = nodes[lastChildId];
    const lastEditable = findNodeById(lastChildId);
    if (lastChild.type === "paragraph" && lastEditable.textContent === "") {
      setFocus(null);
      setActiveNode(null); // For rerendering purposes
      setFocus(lastChildId);
      setActiveNode(lastChildId);
      lastEditable?.focus();
      return;
    }
    const id = e.target.parentElement.getAttribute("data-id");
    const parent = nodes[nodes[id].parent];
    const onlyChild = parent.children?.length === 1;
    const index = blocks.indexOf(parent.id) + 1;
    onlyChild
      ? createNewBlock("paragraph", index)
      : createNode("paragraph", id, node.children.length);
  };

  const handleDragOver = (e) => {
    const target = e.target.closest('[data-item-type="BAR"]');
    const _id = target?.getAttribute('data-id');
    document.querySelector('.drag-over')?.classList.remove('drag-over')
    target.classList.add('drag-over');
    setDestination(_id);
  }

  const handleDragLeave = (e) => {
    const target = e.target.closest('[data-item-type="BAR"]');
    document.querySelector('.drag-over')?.classList.remove('drag-over')
    target.classList.remove('drag-over');
  }

  const handleResize = (size) => {
    const parent = $this.current.parentElement;
    parent.setAttribute('data-resizing-id', id);
    parent.setAttribute('data-resize-size', size);
  }

  return (
    <>
      <Section
        key={id}
        ref={$this}
        className="v-block w-full h-auto flex"
        defaultSize={node.style?.width}
        data-id={id}
        minSize={100}
        onSizeChanged={handleResize}
      >
        <div data-id={id} className="v-block w-full h-full">
          {node?.children?.map((childId) => {
            return renderer(childId, nodes[childId].type);
          })}

          <div className={`${isNotBlock ? 'block' : 'hidden'} w-[95%] min-h-2 h-full mr-4`} onClick={handleClick}></div>
        </div>
      </Section>
      <Bar
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        data-id={id}
        size={15}
        className="adjuster group mx-4"
        style={{ cursor: 'col-resize' }}>
        <span className="hidden group-[.drag-over]:block group-[.drag-over]:bg-gray-500 cursor-col-resize group-hover:block m-auto h-full group-hover:bg-gray-500 w-0.5"></span>
      </Bar>
    </>
  );
};

export default VerticalBlock;
