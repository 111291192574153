import { Container, Section, Bar, Resizer } from '@column-resizer/react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { FaRegComment } from 'react-icons/fa';
import { LuAlignHorizontalSpaceAround } from 'react-icons/lu';
import { HiViewfinderCircle } from "react-icons/hi2";
import { TiDownload } from "react-icons/ti";
import { TbDots } from "react-icons/tb";
import { useEffect, useRef, useState } from 'react';
import { useDBStore } from '../../stores/dbStore';
import { FaImage } from 'react-icons/fa6';
import { Dropzone } from 'dropzone';
import { attachDropzone } from '../../helpers';



function onResizing(resizer) {
  if (resizer.isBarActivated(0)) {
    resizer.resizeSection(2, { toSize: resizer.getSectionSize(0) });
  } else {
    resizer.resizeSection(0, { toSize: resizer.getSectionSize(2) });
  }

  if (resizer.getSectionSize(1) < 300) {
    const remainingSize = resizer.getTotalSize() - 300;
    resizer.resizeSection(0, { toSize: remainingSize / 2 });
    resizer.resizeSection(1, { toSize: 300 });
    resizer.resizeSection(2, { toSize: remainingSize / 2 });
  }
}



export default Image = ({ id }) => {
  const { pages, currentPage, updateNode, setShowMenuButtons, setDestination } = useDBStore((state) => state);
  const { nodes, focusedNode } = pages[currentPage];
  const node = nodes[id];
  const [direction, setDirection] = useState('C');
  const [open, setOpen] = useState(null);
  const uploader = useRef(null);
  const handleUpload = (e) => {
    const config = {
      url: '/uploads/',
      addedfile: file => {
        console.log('File upload', file.name);
        updateNode(id, { src: 'https://images.unsplash.com/photo-1460661419201-fd4cecdf8a8b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb' })
      }
    }
    attachDropzone(e.target, config);
    e.target.dispatchEvent(new MouseEvent('click', { cancelable: true, bubbles: false, view: window }))
  }

  return (
    <>
      {node.src ?
        <Container className="bg-transparent group h-auto whitespace-nowrap" beforeApplyResizer={onResizing}>
          <Section className={`${direction === 'R' || direction === 'C' ? 'flex' : 'hidden'} items-center justify-center bg-transparent`} />
          <Bar size={5} className="group-hover:*:inline-block flex relative  items-center transition cursor-col-resize bg-transparent -mr-2 z-30">
            <span className='hidden h-10 border border-white rounded-t-lg rounded-b-lg w-full bg-gray-800'></span>
          </Bar>
          <Section defaultSize={800} className="group flex items-center justify-center bg-transparent">
            <figure className="relative w-full transition-all duration-300 cursor-pointer">
              <figcaption className="opacity-70 group-hover:block hidden absolute rounded-md px-4 text-lg text-white top-0 right-0">
                <div className="relative flex justify-evenly items-center divide-x rounded-md bg-black opacity-80 h-6 py-3 w-auto my-2 z-10  *:h-5 *:text-center *:flex *:items-center *:justify-center *:px-1.5">
                  <span>
                    <FaRegComment size={18} />
                  </span>

                  <span>
                    <Menu as="div" className="relative inline-block">
                      <MenuButton className="inline-flex w-full justify-center mt-1.5 rounded-md bg-transparent text-sm font-semibold text-white shadow-sm">
                        <LuAlignHorizontalSpaceAround size={18} />
                        <ChevronDownIcon aria-hidden="true" className="size-4 text-white" />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute left-0 z-10 -ml-2.5 -mt-0.5 w-20 origin-top-right rounded-md bg-black shadow-lg transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1 flex justify-evenly">
                          <MenuItem>
                            <LuAlignHorizontalSpaceAround onClick={() => setDirection('L')} />
                          </MenuItem>
                          <MenuItem>
                            <LuAlignHorizontalSpaceAround onClick={() => setDirection('C')} />
                          </MenuItem>
                          <MenuItem>
                            <LuAlignHorizontalSpaceAround onClick={() => setDirection('R')} />
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  </span>
                  <span>
                    <HiViewfinderCircle onClick={() => setOpen(true)} size={18} />
                  </span>
                  <span>
                    <TiDownload size={18} />
                  </span>
                  <span>
                    <TbDots size={18} />
                  </span>
                </div>
              </figcaption>
              <img onClick={handleUpload} className="size-full" src={node.src} alt="image description" />
            </figure>
          </Section>
          <Bar size={5} className="group-hover:*:inline-block flex  items-center transition cursor-col-resize bg-transparent -ml-2">
            <span className='hidden h-10 border border-white rounded-t-lg rounded-b-lg w-full bg-gray-800'></span>
          </Bar>
          <Section className={`${direction === 'L' || direction === 'C' ? 'flex' : 'hidden'} items-center justify-center bg-transparent`} />
        </Container>
        :
        <div className='w-full h-full'>
          <button ref={uploader} onClick={handleUpload} className='upload w-full h-full flex items-center gap-x-3.5 p-1 opacity-30 rounded-md hover:bg-gray-300'>
            <FaImage size={30} /> Add an Image
          </button>
        </div>}
      {open && node.src && <div className='w-screen h-screen flex justify-center overflow-hidden items-center z-[1000] absolute top-0 left-0'>
        <img width='100%' onClick={() => setOpen(null)} src={node.src} alt="" srcset="" />
      </div>}
    </>);
}
