import Paragraph from "./components/plugins/Paragraph";
import Callout from "./components/plugins/Callout";
import Toggle from "./components/plugins/Toggle";
import Todo from "./components/plugins/Todo";
import Heading from "./components/plugins/Heading";
import Divider from "./components/plugins/Divider";
import { OList, UList } from "./components/plugins/List";
import HorizontalBlock from "./components/plugins/HorizontalBlock";
import VerticalBlock from "./components/plugins/VerticalBlock";
import Wrapper from "./components/content/Wrapper";
import { PiTextAaBold } from "react-icons/pi";
import { LuHeading1, LuHeading2, LuHeading3 } from "react-icons/lu";
import { GrTextAlignLeft } from "react-icons/gr";
import { FaImage, FaQuoteLeft } from "react-icons/fa6";
import { FaListUl, FaListOl } from "react-icons/fa";
import { LuListTodo } from "react-icons/lu";
import { RiPlayList2Fill } from "react-icons/ri";
import { AiOutlineBorderVerticle } from "react-icons/ai";
import Quote from "./components/plugins/Quote";
import Page from "./components/plugins/Page";
import { MdDeleteForever } from "react-icons/md";
import { ImMoveUp, ImMoveDown } from "react-icons/im";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { LuRepeat2 } from "react-icons/lu";
import { IoIosColorPalette } from "react-icons/io";
import { ColorBlock, TurnIntoBlock } from "./components/ExtraMenus";
import Image from "./components/plugins/Image";

export const Menus = [
  {
    name: "Text",
    type: "paragraph",
    icon: <PiTextAaBold />,
    description: "Add a Text",
    shortcut: "⌘ + ⌥ + T",
  },
  {
    name: "Heading",
    type: "heading",
    icon: <LuHeading1 />,
    element: "h1",
    description: "Add a Heading",
    shortcut: "⌘ + ⌥ + H",
  },
  {
    name: "Heading",
    type: "heading",
    icon: <LuHeading2 />,
    element: "h2",
    description: "Add a Heading",
    shortcut: "⌘ + ⌥ + H",
  },
  {
    name: "Heading",
    type: "heading",
    icon: <LuHeading3 />,
    element: "h3",
    description: "Add a Heading",
    shortcut: "⌘ + ⌥ + H",
  },
  {
    name: "Call Out",
    type: "callout",
    icon: <GrTextAlignLeft />,
    description: "Add Colorful Panel",
    shortcut: "⌘ + ⌥ + C",
  },
  {
    name: "Page",
    type: "page",
    icon: <GrTextAlignLeft />,
    description: "Add a page",
    shortcut: "⌘ + ⌥ + C",
  },
  {
    name: "BlockQuote",
    type: "quote",
    icon: <FaQuoteLeft />,
    description: "Add a Text",
    shortcut: "⌘ + ⌥ + Q",
  },
  {
    name: "Image",
    type: "image",
    icon: <FaImage />,
    description: "Add an image",
    shortcut: "⌘ + ⌥ + Q",
  },
  {
    name: "Ordered List",
    type: "olist",
    icon: <FaListOl />,
    description: "Add Numbered List",
    shortcut: "⌘ + ⌥ + L",
  },
  {
    name: "UnOrdered List",
    type: "ulist",
    icon: <FaListUl />,
    description: "Add Bullet List",
    shortcut: "⌘ + ⌥ + L",
  },
  {
    name: "Todo List",
    type: "todo",
    icon: <LuListTodo />,
    description: "Add a Todo List",
    shortcut: "⌘ + ⌥ + R",
  },
  {
    name: "Toggle List",
    type: "toggle",
    icon: <RiPlayList2Fill />,
    description: "Create a Toggle Panel",
    shortcut: "⌘ + ⌥ + T",
  },
  {
    name: "Line Break",
    type: "divider",
    icon: <AiOutlineBorderVerticle />,
    description: "Add Line Break",
    shortcut: "⌘ + ⌥ + D",
  },
];

export const SubMenus = [
  {
    name: "Turn into",
    cmd: () => { },
    icon: <LuRepeat2 size={80} />,
    menu: <TurnIntoBlock />,
    shortcut: "⌘ + ⌥ + D",
  },
  {
    name: "Duplicate",
    cmd: () => { },
    icon: <HiOutlineDocumentDuplicate size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  { separator: true },
  {
    name: "Move Up",
    cmd: () => { },
    icon: <ImMoveUp size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  {
    name: "Delete",
    cmd: () => { },
    icon: <MdDeleteForever size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  {
    name: "Move Down",
    cmd: () => { },
    icon: <ImMoveDown size={80} />,
    shortcut: "⌘ + ⌥ + D",
  },
  { separator: true },
  {
    name: "Color",
    cmd: () => { },
    icon: <IoIosColorPalette size={80} />,
    menu: <ColorBlock />,
    shortcut: "⌘ + ⌥ + D",
  },
];

export const Structures = {
  paragraph: {
    type: "paragraph",
    text: "",
    children: [],
  },
  callout: {
    type: "callout",
    text: "",
    icon: "",
    background: "",
    children: [],
  },
  page: {
    type: "page",
    text: "",
    icon: "",
    link: "",
  },
  toggle: {
    type: "toggle",
    collapsed: false,
    text: "",
    children: [],
  },
  todo: {
    type: "todo",
    text: "",
    done: false,
    children: [],
  },
  image: {
    type: "image",
    text: ""
  },
  quote: {
    type: "quote",
    text: "",
    children: [],
  },
  heading: (element) => ({
    type: "heading",
    text: "",
    element,
  }),
  divider: {
    type: "divider",
  },
  ulist: {
    type: "ulist",
    text: "",
    children: [],
  },
  olist: {
    type: "olist",
    text: "",
    children: [],
  },
  horizontalBlock: {
    type: "horizontalBlock",
    children: [],
  },
  verticalBlock: {
    type: "verticalBlock",
    children: [],
  },
};

export const Elements = {
  paragraph: (id, fn) => (
    <Wrapper id={id}>
      <Paragraph id={id} renderer={fn} />
    </Wrapper>
  ),
  callout: (id, fn) => (
    <Wrapper id={id}>
      <Callout id={id} renderer={fn} />
    </Wrapper>
  ),
  page: (id, fn) => (
    <Wrapper id={id}>
      <Page id={id} renderer={fn} />
    </Wrapper>
  ),
  toggle: (id, fn) => (
    <Wrapper id={id}>
      <Toggle id={id} renderer={fn} />
    </Wrapper>
  ),
  image: (id) => (
    <Wrapper id={id}>
      <Image id={id} />
    </Wrapper>
  ),
  todo: (id, fn) => (
    <Wrapper id={id}>
      <Todo id={id} renderer={fn} />
    </Wrapper>
  ),
  quote: (id, fn) => (
    <Wrapper id={id}>
      <Quote id={id} renderer={fn} />
    </Wrapper>
  ),
  heading: (id, fn) => (
    <Wrapper id={id}>
      <Heading id={id} renderer={fn} />
    </Wrapper>
  ),
  divider: (id, fn) => (
    <Wrapper id={id}>
      <Divider id={id} />
    </Wrapper>
  ),
  ulist: (id, fn) => (
    <Wrapper id={id}>
      <UList id={id} renderer={fn} />
    </Wrapper>
  ),
  olist: (id, fn) => (
    <Wrapper id={id}>
      <OList id={id} renderer={fn} />
    </Wrapper>
  ),
  horizontalBlock: (id, fn) => <HorizontalBlock id={id} renderer={fn} />,
  verticalBlock: (id, fn) => <VerticalBlock id={id} renderer={fn} />,
};




export const colors = [
  { name: "Colors", type: "title" },
  { type: "delimiter" },
  { name: "Default", type: "color", color: "gray" },
  { name: "Brown", type: "color", color: "brown" },
  { name: "Orange", type: "color", color: "orange" },
  { name: "Yellow", type: "color", color: "yellow" },
  { name: "Green", type: "color", color: "green" },
  { name: "Blue", type: "color", color: "blue" },
  { name: "Backgrounds", type: "title" },
  { type: "delimiter" },
  { name: "Default Background", type: "background", color: "gray" },
  { name: "Brown Background", type: "background", color: "brown" },
  { name: "Orange Background", type: "background", color: "orange" },
  { name: "Yellow Background", type: "background", color: "yellow" },
  { name: "Green Background", type: "background", color: "green" },
  { name: "Blue Background", type: "background", color: "blue" },
];